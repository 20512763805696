const AccountModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const Profile = () => import( /* webpackChunkName: "profile" */'./views/Profile.vue');
const AccountStatement = () => import(/* webpackChunkName: "account" */ './views/AccountStatement.vue');
const EditStakes = () => import(/* webpackChunkName: "account" */ '@/modules/account/components/EditStakes.vue'); 
const BetHistory = () => import(/* webpackChunkName: "betHistory" */ './views/BetHistory.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "ProfitLossDetail" */ './views/ProfitLossDetail.vue');
const CasinoWinLossDetails = () => import(/* webpackChunkName: "casino-win-loss" */ './components/CasinoWinLossDetail.vue');
const UnsettledBets = () => import(/* webpackChunkName: 'unsettled-bets' */ './views/UnsettledBets.vue');

const AccountRoutes =   {
    path: '/',
    component: AccountModule,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/statement',
        name: 'AccountStatement',
        component: AccountStatement
      },
      {
        path: '/edit-stakes',
        name: 'EditStakes',
        component: EditStakes
      },
      {
        path:'/bet-history',
        name:'betHistory',
        component:BetHistory
      },
      {
        path:'/profit-loss-detail',
        name:'profitLossDetail',
        component:ProfitLossDetail
      },
      {
        path: '/profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
      {
        path: 'casino-win-loss-details',
        name: 'casino-win-loss-details',
        component: CasinoWinLossDetails,
      },
      {
        path: 'unsettled-bets',
        name: 'unsettled-bets',
        component: UnsettledBets
      },
      {
        path: 'settled-bets',
        name: 'settled-bets',
        component: UnsettledBets
      }
    ],
  }

export default AccountRoutes;