<template>
    <div class="modal fade " id="Register" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img loading="lazy" :src="$siteSettings?.data?.aws_url + $siteSettings?.data?.storage_path.domain_image + $siteSettings?.data?.logo" alt="pop-logo">
                        </figure>
                        <button @click="resetData()" type="button" ref="close_btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close-btn">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form">
                            <h2 class="register-title">{{ $t('register-account','Register Account')}}</h2>
                            <!-- <p class="create t-center reg-subtitle">{{ $t('create-account-with-mobile-number','Create Account With Mobile Number')}}</p> -->
                            <!-- <hr class="form-divider-line reg-hr"> -->
                            <div class="form-group">
                                <!-- <label for="" class="form-cmn-title">{{ $t('mobile-number','Mobile Number')}}*</label> -->
                                <div class="get-mob">
                                    <div class="phone-inpt-sec">
                                        <div class="slt-country-code">
                                            <select v-model="form.code" class="form-select" id="autoSizingSelect" :disabled="otpSent">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                        <div class="input-number-box">
                                            <input type="tel" v-model="form.mobileNo" maxlength="10" minlength="10" 
                                                :placeholder="$t('mobile-number','Mobile Number') + '*'"  class="form-control add"
                                                :disabled="timerRunning || otpSent" @input="form.mobileNo = $helper.sanitizeNumber(form.mobileNo,9999999999);">
                                            <div class="get_otp_auth_btn">
                                                <button :disabled="timerRunning || otpLoading" @click="otpBtnCall()"
                                                    class="btn cmn-bdr-btn otp-btn">
                                                    <span v-if="timerRunning">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                                    <span v-else>{{ resendOtp ? $t('resend-otp' , 'Resend OTP') : $t('get-otp' , 'Get OTP') }} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="thm-heading justify-content-start" v-for="(err, index) in checkValidation?.mobile" :key="index">
                                    <p class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                                <div class="thm-heading justify-content-start" v-if="otpBtnInit && form.mobileNo?.length == 10 && !timerRunning && getOTPmsg && !isOtpApiCalled">
                                    <p class="wrong-color">{{$t('please-click-on-send-otp','Please Click On Get OTP')}}</p>
                                </div>
                            </div>

                            
                            <div class="form-group" v-if="otpSent">
                                <!-- <label for="" class="form-cmn-title">{{$t('otp' , 'OTP') }}*</label> -->
                                <div class="get-mob">
                                    <input v-model="form.otp" type="text" class="form-control" maxlength="6" :placeholder="$t('otp','OTP') + '*'"
                                        @input="form.otp = $helper.sanitizeNumber(form.otp, 999999);">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/key_icon.svg" alt="key"></div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.otp?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.otp" :key="index"  class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>
                            <div class="input-left mb-2" v-if ="!isSetUserId">
                                <div class="user-password-wrapper text-right">
                                    <a href="javascript:void(0)" class="text-decoration-underline" @click="isSetUserId =! isSetUserId">
                                        {{ $t('set-user-id', 'Want To Set UserID') }}?
                                    </a>
                                </div>
                            </div>
                            <div class="form-group pass-m-top" v-if="isSetUserId">
                                <!-- <label for="" class="form-cmn-title">{{ $t('user-id','User ID')}}*</label> -->
                                <div class="get-mob">
                                    <input type="text" v-model="form.userName" class="form-control" @input="form.userName = $helper.sanitizeDetails('userId', form.userName, 25);"
                                        :placeholder="$t('user-id','User ID')" minlength="6" maxlength="25"  required="1">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/user_icon.svg" alt="user-icon"></div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.userId?.length > 0">
                                    <p class="wrong-color" v-for="(err, index) in checkValidation?.userId" :key="index">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>
                            

                            <div class="form-group">
                                <!-- <label for="" class="form-cmn-title">{{$t('password','Password')}}*</label> -->
                                <div class="get-mob">
                                    <input :type="passwordFieldType" v-model="form.password" class="form-control" id="inputPass4"
                                        :placeholder="$t('password','Password') + '*'" @input="form.password = $helper.sanitizeDetails('password', form.password, 20);" maxlength="20">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/password_icon.svg" alt="pass"></div>
                                    <div class="eye-icn-sec" @click="togglePasswordVisibility('password')">
                                        <img loading="lazy" v-if="passwordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                        <img loading="lazy" v-else src="@/assets/images/eye-open.webp" alt="eye-open">
                                    </div>
                                </div>
                                <div class="justify-content-start" v-if="init && checkValidation?.password?.length > 0">
                                    <p class="wrong-color" v-for="(err, index) in checkValidation?.password" :key="index">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <label for="" class="form-cmn-title">{{$t('confirm-password','Confirm Password')}}*</label>  -->
                                <div class="get-mob">
                                    <input :type="confPasswordFieldType" v-model="form.confirmPassword" @input="form.confirmPassword = $helper.sanitizeDetails('password', form.confirmPassword, 20);" required="1" maxlength="20" minlength="8" class="form-control" id="inputPass4"
                                        :placeholder="$t('confirm-password','Confirm Password') + '*'">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/password_icon.svg" alt="pass"></div>
                                    <div class="eye-icn-sec" @click="togglePasswordVisibility()">
                                        <img loading="lazy" v-if="confPasswordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                        <img loading="lazy" v-else src="@/assets/images/eye-open.webp" alt="eye-open">
                                    </div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.confirmPassword?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.confirmPassword" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>
                            <div class="form-container-sec text-right" v-if ="!isRefral && !hasReferCode">
                                <a href="javascript:void(0)" class="text-decoration-underline" @click="isRefral =! isRefral">
                                    {{ $t('have-a-refferal-code', 'Have a Referral Code') }}?
                                </a>
                            </div>
                            <div class="form-group mb-0"  v-if ="isRefral || hasReferCode">
                                <!-- <label for="referCode" class="form-cmn-title">{{$t('refer-code','Refer Code')}}</label> -->
                                <div class="get-mob">
                                    <input type="tel" v-model="form.referCode" class="form-control" id="referCode"
                                        :placeholder="$t('refer-code','Refer Code')" @input="form.referCode = $helper.sanitizeNumber(form.referCode,999999);" :disabled="hasReferCode">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/reffral_icon.svg" alt="refer-icon"></div>
                                </div>
                            </div>
                            <div class="get-mob">
                                <a href="javascript:void(0)" @click="signUpCall()" class="btn btn-submit btn-login" :class="{ 'btn-loading': loading }">
                                    <!-- <span><b></b><b></b><b></b></span> -->
                                    {{$t('register','Register')}} 
                                    <span><img src="@/assets/images/loading.gif"></span>
                                </a>
                            </div>
                            <div v-if="$siteSettings?.data && $siteSettings?.data.social_link && $siteSettings?.data.social_link.whatsappno1"
                                class="whats-app">
                                <p class="create t-center">
                                    {{$t('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}
                                </p>
                                <div class="button-whatsapp">
                                    <a :href="'//wa.me/' + $siteSettings?.data.social_link.whatsappno1" target="_blank"
                                        class="btn-whatsapp"> <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="whatsapp"> {{$t('whatsapp-now','Whatsapp Now')}}
                                    </a>
                                </div>
                            </div>
                            <p class="forpass-in ">{{$t('already-have-account','Already Have Account?')}}<a href="#loginModal" @click="resetData(); $emit('open-login')" data-bs-toggle="modal">{{$t('login','Login')}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import commonAuth from '../services/commonAuth';
import validate from '../services/authValidation';
export default {
    name: "SignUp",
    extends: commonAuth,
    data() {
        return {
            form: {
                code: this.$siteSettings?.data && this.$siteSettings?.data.country_code ? this.$siteSettings?.data.country_code : 91,
                mobileNo: "",
                otp: '',
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
            },
            phnInit: false,
            loading: false,
            otpBtnInit:false,
            isRefral:false,
            isSetUserId: this.getQueryParam("refer_code", window.location.href) ? true : false,
            hasReferCode: (window.location.href).includes('refer_code'),
        };
    },
    computed: {
        checkValidation() {
            const validationErrors = validate.areFieldsValid(
                'signup',
                this.form.mobileNo, 
                this.form.otp, 
                this.form.userName, 
                this.form.password,
                this.form.confirmPassword
            );
            if(this.init || this.phnInit){
                return validationErrors;
            }
        },
    },
    methods: {
        signUpCall() {
            if(!this.isOtpApiCalled) {
                this.getOTPmsg = true;
            }
            this.phnInit = true;
            this.otpBtnInit = true;
            if (!this.otpSent){ 
                return
            } else {
                this.init = true;
                if(Object.keys(this.checkValidation).length === 0) {
                    this.signUpServiceCall();
                }
            }
        },
        otpBtnCall(){
            this.getOTPmsg = true;
            this.phnInit=true;
            this.otpBtnInit=true;

            if(!this.checkValidation?.mobile?.length && !this.timerRunning) {
                this.sendOtpCall();
            }
        },
        signUpServiceCall() {
            this.loading = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            this.$apiCall('post', 'REGISTER', data).then(response => {
                if (response) {
                    this.loading = false;
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.$errorToast(response.data.debug[0]);
                        } else {
                            this.$successToast(response.data.message);
                            this.resetData();
                            this.isSetUserId = false;
                            this.$helper.closeModal('registerModal');
                            this.$helper.showLogin();                        
                        }
                    }
                }
            }).catch(error => {
                if (error)
                    this.loading = false;
                    this.$errorToast(error[0]);
            });
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        showErrorModalFunc(message) {
            this.$errorToast(message);
        },
        showSuccessModalFunc(message) {
            this.$successToast(message);
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }   
        },
        resetData() {
            if(this.hasReferCode) {
                this.hasReferCode = false;
                this.$router.push({name: 'Home'});
            }
            this.toggleRegister()
            this.isSetUserId = false;
            this.isRefral = false
            this.refreshData()
        },
        toggleRegister() {
            const registerBtn = document.getElementById('RegisterBtn');
            if (registerBtn) registerBtn.classList.remove('active');
        }
    },
    mounted() {
        if(this.$route.name == 'Home' && (window.location.href).includes('refer_code') && !this.$store.getters.isAuthenticated && this.$siteSettings?.data?.business_type == 2){
            setTimeout(()=> {
                this.$helper.openModal("#Register")
            }, 1000)
        }
        document.body.classList.add('login-body-bg', 'inner-body');
        document.body.classList.add('login-body-bg', 'inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    }
};
</script>
<style scoped>
.reg-hr{
    height: 2.3px !important;
}

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #B5DDF0;
    }

    50% {
        background-color: #CFE9F6;
    }

    100% {
        transform: scale(1);
        background-color: #FFFFFF;
    }
}

.btn.btn-loading span {
    display: flex;
    justify-content: center;
}

.btn.btn-loading span b {
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: stretch;
    animation-play-state: running;
    animation-timing-function: ease-out;
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
    animation-delay: 0.1s;
    margin: 0 5px;
}

.btn.btn-loading span b:first-child {
    animation-delay: 0s;
    margin: 0;
}

.btn.btn-loading span b:last-child {
    animation-delay: 0.2s;
    margin: 0;
}

/* .btn span {
    display: none;
} */

.placed-bet-btn.btn-loading {
    padding: 10px;
}

.registration-form .btn-submit.btn-login {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}
.registration-form .btn-submit.btn-login img {
    height: 20px;
    width: 20px;
}
.btn-submit.btn-login span{
    display: none;
}
.btn-submit.btn-login.btn-loading span {
    display: flex;
    justify-content: center;
}
</style>