
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");

import SportsRoutes from "@/modules/sports";
import WalletRoutes from "@/modules/wallet";
import MyMarketRoutes from "@/modules/my-market";
import StatementRoutes from "@/modules/statement";
import BetsRoutes from "@/modules/bets";
// import VirtualSportRoutes from "@/modules/virtual-sport";
// import SportBookRoutes from "@/modules/sports-book";

import Login from '@/modules/authorization/components/Login.vue'
import SignUp from '@/modules/authorization/components/SignUp.vue'
import ForgetPassword from '@/modules/authorization/components/ForgetPassword.vue'
import MainTerms from '@/shared/components/Terms/Terms.vue'
import Rules from '@/modules/account/components/Rules.vue'
import BonusRoutes from "@/modules/bonus";
import MatkaRoutes from "@/modules/matka";
import RaceRoutes from "@/modules/race";
import ReferEarnRoutes from "@/modules/refer-earn";
import GamesRoutes from "@/modules/games";
import CricketFightRoutes from "@/modules/cricket-fight";
import NotificationsRoutes from "@/modules/notification";
import NotFound from "@/shared/components/no-data/404.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/forgot-password",
    name: "ForgetPassword",
    component: ForgetPassword
  },
  {
    path: "/terms",
    name: "MainTerms",
    component: MainTerms
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceComponent
  },


  // CricketFightRoutes,
  // FavouriteRoutes,
  SportsRoutes,
  WalletRoutes,
  MyMarketRoutes,
  StatementRoutes,
  BetsRoutes,
  BonusRoutes,
  MatkaRoutes,
  RaceRoutes,
  ReferEarnRoutes,
  WalletRoutes,
  GamesRoutes,
  CricketFightRoutes,
  NotificationsRoutes,
  // VirtualSportRoutes,
  // SportBookRoutes
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: NotFound 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


export default router;
