import { helper } from "@/shared/services/helper";

const regexMap = {
    passwordRegex : /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]{8,20}$/,
    passwordUpperCaseRegex : /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/    ,
    userRegex : /^(?!.*\s)[A-Za-z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|-]{6,25}$/,
    referCodeRegex : /^[a-zA-Z0-9]+$/,
    withdrawPasswordRegex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[\S]+$/
};

const validate = {
    areFieldsValid(type, prop, prop2, prop3, prop4, prop5) {
        const errorObj = {};
    
        const addError = (type, error, lang) => {
            if (!errorObj[type]) {
                errorObj[type] = [];
            }
            errorObj[type].push({ error, lang });
        };
    
        const validateMobile = (mobile) => {
            if (mobile.length !== 10) {
                addError('mobile', 'Mobile Number Should Be Of 10 Digits.', 'mobile-number-should-be-of-10-digits');
            }
        };
        const validatePassword = (password) => {
            if (!helper.testRegex(regexMap.passwordUpperCaseRegex, password)) {
                addError('password', 'Password Must Contain Alphabets, Numbers, At Least 1 In Capital Case & At Least 1 In Lower Case.', 'password-capital-case');
            }
            if (!helper.testRegex(regexMap.passwordRegex, password)) {
                addError('password', 'Password Must Be Of Minimum 8 Characters And Maximum 20 Characters', 'must-between-8-20-characters-and-should-be-alph');
            }
        };
    
        const checkPasswordMatch = (password, confirmPassword) => {
            if (password !== confirmPassword) {
                addError('confirmPassword', 'Password Mismatch.', 'password-mismatch');
            }
        };

         // Withdraw password
         const validateWithdrawPassword = (password) => {
            if (password.length < 8 || password.length > 20) {
                addError('password', 'Password must be between 8 and 20 characters.', 'pass-min-8-max-20');
            }
            if (/\s/.test(password)) {
                addError('password', 'Password should not contain spaces.', 'password-no-spaces');
            }
            if (!helper.testRegex(regexMap.withdrawPasswordRegex, password)) {
                addError('password', 'Password Must Contain Alphabets, Numbers, special characters And At Least 1 In Capital Case, And 1 In Lower Case.', 'password-valid-msg');
            }
        };
    
        // Validation for each auth type
        if (type === 'login') {
            const tab = prop, mobile = prop2, userId = prop3, password = prop4;
            if (tab == 1 && mobile === '') {
                addError('mobile', 'Please Enter Mobile Number', 'please-enter-mobile-number');
            }
            if (tab == 2 && userId === '') {
                addError('userId', 'Please Enter User ID', 'please-enter-user-name');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            }
        }
        
        if (type === 'signup') {
            const mobile = prop, otp = prop2, userId = prop3, password = prop4, confirmPassword = prop5;
            validateMobile(mobile);
            if (otp == '' && otp?.length == 0) {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp?.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'otp-6-digit-msg');
            }
            if (userId && userId.length < 6 || userId.length > 25) {
                addError('userId', 'User ID Must be between 6 to 25 Characters.', 'userid-must-contain-alphanumeric-and-between-6-to-25-characters');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            } else {
                validatePassword(password);
            }
            if (confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (confirmPassword != '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
    
        if (type === 'forgot-password') {
            const mobile = prop, otp = prop2, password = prop3, confirmPassword = prop4;
            validateMobile(mobile);
            if (otp == '' && otp?.length == 0) {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp?.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'otp-6-digit-msg');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            } else {
                validatePassword(password);
            }
            if (confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (confirmPassword !== '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
    
        if (type === 'change-password') {
            const oldPassword = prop, newPassword = prop2, confirmPassword = prop3;
            if (oldPassword === '') {
                addError('oldPassword', 'Please Enter Old Password', 'please-enter-old-password');
            }
            if (newPassword === '') {
                addError('password', 'Please Enter New Password', 'please-enter-new-password');
            } else {
                validatePassword(newPassword);
            }
            if (confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm New Password', 'confirm-new-password');
            }
            if (confirmPassword !== '') {
                checkPasswordMatch(newPassword, confirmPassword);
            }
        }

        // Withdraw password
        if (type === 'withdraw-password') {
            const otp = prop, password = prop2, confirmPassword = prop3;
            if (otp === '') {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'please-enter-otp-6');
            }
            validateWithdrawPassword(password);
            if (password !== '' && confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (password !== '' && confirmPassword !== '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
    
        return errorObj;
    }
    
    
};
export default validate;