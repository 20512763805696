<template>
    <div class="modal fade" id="loginModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img loading="lazy" :src="$siteSettings?.data?.aws_url + $siteSettings?.data?.storage_path.domain_image + $siteSettings?.data?.logo" alt="pop-logo">
                        </figure>
                        <button ref="close_btn" type="button" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close" @click="refreshData($siteSettings?.data?.business_type == 2 ? 1 : 2)">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close-btn">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form tig-login-form-wrapper">
                            <h2  class="register-title">{{ $t('login-cap','LOGIN')}}</h2>
                            <!-- <hr class="form-divider-line"> -->
                            <!-- <div class="get-mob">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="col-auto">
                                            <select v-model="form.type" class="form-select" id="autoSizingSelect">
                                                <option value="1">Phone</option>
                                                <option value="2">ID</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div v-if="form.type == 1" class="col-auto">
                                            <input type="text" v-model="form.mobileNo" class="form-control add"
                                                id="autoSizingInput" placeholder="Enter Number">
                                        </div>

                                        <div v-if="form.type == 2" class="col-auto">
                                            <input type="text" v-model="form.userId" class="form-control add"
                                                id="autoSizingInput" placeholder="Enter ID">
                                        </div>
                                    </div>
                                    <div v-if="form.type == 1" class="phone-country demo col-2">
                                        <div class="mm-dropdown-con ">
                                            <select v-model="form.code" class="form-select textfirst-con "
                                                aria-label="Default select example">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="tab-login-sec">
                                <ul class="nav nav-tabs" id="myTab" role="tablist"  v-if="$siteSettings?.data?.business_type == 2">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" :class="form.type == 1 ? 'active': ''" id="phone-tab" data-bs-toggle="tab"
                                            data-bs-target="#phone" type="button" role="tab" aria-controls="phone"
                                            aria-selected="true" @click="form.type = 1,refreshData(1)">{{ $t('mobile-number','Mobile Number')}}</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" :class="form.type == 2 ? 'active': ''" id="user-tab" data-bs-toggle="tab" data-bs-target="#user"
                                            type="button" role="tab" aria-controls="user" aria-selected="false"
                                            @click="form.type = 2,refreshData(2)">{{ $t('user-id','User ID')}}</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent" v-if="$siteSettings?.data?.business_type == 2">
                                    <div class="tab-pane fade" :class="form.type == 1 ? 'show active': ''" id="phone" role="tabpanel" aria-labelledby="phone-tab">
                                        <!-- <label for="" class="form-cmn-title">{{ $t('mobile-number','Mobile Number')}}*</label> -->
                                        <div class="phone-inpt-sec">
                                            <div class="slt-country-code">
                                                <select v-model="form.code" class="form-select textfirst-con " :disabled="demoLoading || loading"
                                                aria-label="Default select example">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                            <div class="get-mob">
                                                <div class="input-number-box">
                                                    <input type="tel"  @input="form.mobileNo = $helper.sanitizeNumber(form.mobileNo,9999999999);"  minlength="10" maxlength="10" v-model="form.mobileNo" 
                                                    :disabled="demoLoading || loading"
                                                    class="form-control add" id="autoSizingInput" :placeholder="$t('mobile-number','Mobile Number') + '*'">
                                                </div>
                                                <div  class="justify-content-start  password-wrong-con" v-if="init && checkValidation?.mobile?.length > 0">
                                                    <p v-for="(err, index) in checkValidation?.mobile" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="form.type == 1">
                                            <div class="d-flex login-method-section mt-2 mb-2">
                                                <div>
                                                    <input class="form-check-input flexRadioDefault-sec" type="radio" name="login-method" id="login-password" value="password" v-model="loginMethod"  @change="form.password = ''">
                                                    <label for="login-password" class="ps-1 text-white">{{$t('password', 'Password')}}</label>
                                                </div>
                                                <div class="ms-2">
                                                    <input class="form-check-input flexRadioDefault-sec" type="radio" name="login-method" id="login-otp" value="otp" v-model="loginMethod" @change="form.password = ''">
                                                    <label for="login-otp" class="ps-1 text-white">{{$t('otp', 'OTP')}}</label>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="user" role="tabpanel" aria-labelledby="user-tab">
                                        <div class="form-group">
                                            <!-- <label for="" class="form-cmn-title">{{ $t('user-id','User ID')}}*</label> -->
                                            <div class="get-mob">
                                                <input type="text" v-model="form.userId" class="form-control add" id="autoSizingInput" @input="validateUserId" 
                                                :placeholder="$t('user-id','User ID') + '*'" :disabled="demoLoading || loading">
                                                <div class="inp-img"><img loading="lazy" src="@/assets/images/user_icon.svg" alt=""></div>
                                            </div>
                                            <div  class="justify-content-start password-wrong-con" v-if="init && checkValidation?.userId?.length > 0">
                                                <p v-for="(err, index) in checkValidation?.userId" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent" v-else>
                                    <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                        <div class="form-group">
                                            <!-- <label for="" class="form-cmn-title">{{ $t('user-id','User ID')}}*</label> -->
                                            <div class="get-mob">
                                                <input type="text" v-model="form.userId" @input="validateUserId" class="form-control add" id="autoSizingInput" 
                                                :placeholder="$t('user-id','User ID') + '*'" :disabled="demoLoading || loading">
                                                <div class="inp-img"><img loading="lazy" src="@/assets/images/user_icon.svg" alt=""></div>
                                            </div>
                                            <div  class="justify-content-start password-wrong-con" v-if="init && checkValidation?.userId?.length > 0">
                                                <p v-for="(err, index) in checkValidation?.userId" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group mb-0">
                                <div class="get-mob"  v-if="loginMethod == 'password'">
                                    <!-- <label for="" class="form-cmn-title">{{$t('password','Password')}}*</label> -->
                                    <div class="get-mob password-input-field">
                                        <input :type="passwordFieldType" v-model="form.password" @input="validatePassword" :disabled="demoLoading || loading"
                                        class="form-control" id="inputPassword2" :placeholder="$t('password','Password') + '*'">
                                        <div class="inp-img"><img loading="lazy" src="@/assets/images/key_icon.svg" alt="key"></div>
                                        <div class="eye-icn-sec" @click="toggleShow('password')">
                                            <img loading="lazy" v-if="passwordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="eye-open" >
                                            <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="eye-close">
                                        </div>
                                    </div>
                                </div>
                                <div class="get-mob"  v-if="loginMethod == 'otp'">
                                    <!-- <label for="" class="form-cmn-title">{{$t('otp','OTP')}}*</label> -->
                                    <div class="otp_field_wrapper">
                                        <input @input="form.password = $helper.sanitizeNumber(form.password,999999);" type="text" maxlength="6" v-model="form.password" :placeholder="$t('otp','OTP') + '*'" class="form-control" id="inputPassword2" >
                                        <span class="btn btn-primary place-but send-login-otp password-show-hide" :class="{'btn-disabled' : isOtpApiCalled && (minutes > 0 || seconds > 0) || loadingOtp}" v-if="loginMethod == 'otp'" @click="sendOtpServiceCall()">
                                            {{ isOtpApiCalled && (minutes > 0 || seconds > 0) ? formatTime(minutes) + ':' + formatTime(seconds) : loadingOtp ? '' : (minutes == 0 ? $t('resend-otp','Resend OTP') : $t('get-otp','Get OTP')) }}
                                            <div v-if="loadingOtp" class="loader_spinner">
                                                <div class="spinner-border auth_loader otp_btn_loader" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </span> 
                                    </div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.password?.length > 0">
                                    <p class="wrong-color" v-for="(err, index) in checkValidation?.password" :key="index">{{ $t(`please-enter-${loginMethod.toLowerCase()}`,`Please Enter ${loginMethod}.`)}}</p>
                                </div>
                            </div>
                           
                            


                            <div class="check-box-sec form-container-sec" v-if="$siteSettings?.data && $siteSettings?.data?.business_type == 2">
                                <!-- <div class="box-left">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="check1" name="option1" value="something">
                                        <label class="form-check-label" for="check1">{{$t('keep-me-logged-in','Keep Me Logged In')}}</label>
                                    </div>
                                </div> -->
                                <div class="for-pass">
                                    <a data-bs-toggle="modal" href="#staticBackdrop-two" @click="refreshData(1)">
                                        {{$t('forgot-password','Forgot Password?')}}
                                    </a>
                                </div>
                            </div>

                            <div class="login-option">
                                <div class="form-group demo-login">
                                    <a @click="demoUserLogin()" class="btn btn-submit btn-login" :class="{ 'btn-loading': demoLoading }">
                                        {{$t('demo-login','Demo Login')}} 
                                        <!-- <span><b></b><b></b><b></b></span> -->
                                        <span><img loading="lazy" src="@/assets/images/loading.gif"></span>
                                    </a>
                                </div>
                                <div class="form-group">
                                    <a @click="signInMethodCall()" class="btn btn-submit btn-login" :class="{ 'btn-loading': loading }">
                                        {{$t('login','Login')}} 
                                        <!-- <span><b></b><b></b><b></b></span> -->
                                         <span><img loading="lazy" src="@/assets/images/loading.gif"></span>
                                    </a>
                                </div>
                            </div>
                            <div v-if="$siteSettings?.data && $siteSettings?.data?.social_link && $siteSettings?.data?.social_link?.whatsappno1 && $siteSettings?.data?.business_type == 2"
                                class="whats-app">
                                <p class="create t-center">
                                    {{$t('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}
                                </p>
                                <div class="button-whatsapp">
                                    <a :href="'//wa.me/' + $siteSettings?.data?.social_link.whatsappno1" target="_blank"
                                        type="button" class="btn-whatsapp"> <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="whatsapp">
                                        {{$t('whatsapp-now','Whatsapp Now')}} </a>
                                </div>
                            </div>
                            <div class="other-login-option" v-if="$siteSettings?.data && $siteSettings?.data?.business_type == 2">
                                 <h3>{{$t('or-login-with','Or Login With')}}</h3>
                                 <ul class="login-by-social-icon">
                                     
                                     <li>
                                        <a v-if="$siteSettings?.data?.social_link?.facebook_link" :href="$siteSettings?.data?.social_link?.facebook_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/facebook.webp" alt="facebook">
                                        </a>
                                    </li>
                                     
                                     <li>
                                        <a v-if="$siteSettings?.data?.social_link?.telegram_link" :href="$siteSettings?.data?.social_link?.telegram_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/telegram.webp" alt="telegram">
                                        </a>
                                    </li>
                                     
                                     <li>
                                        <a v-if="$siteSettings?.data?.social_link?.instagram_link" :href="$siteSettings?.data?.social_link?.instagram_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/instagram.webp" alt="instagram">
                                        </a>
                                    </li>
                                    
                                 </ul>
                             </div>

                            
                            
                            <p class="whats-with-acc" v-if="$siteSettings?.data && $siteSettings?.data?.business_type == 2">{{$t('dont-have-an-account',"Don't Have An Account?")}} &nbsp;<a data-bs-toggle="modal"
                                    data-bs-target="#Register" @click="refreshData(1); $emit('open-register')">{{$t('register','Register')}}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import validate from '../services/authValidation';
export default {
    name: "Login",
    computed: {
        checkValidation() {
            const validationErrors = validate.areFieldsValid(
                'login', 
                this.form.type, 
                this.form.mobileNo, 
                this.form.userId, 
                this.form.password
            );
            if(this.init){
                return validationErrors;
            }
        }
    },
    data() {
        return {
            form: {
                code: this.$siteSettings?.data && this.$siteSettings?.data.country_code ? this.$siteSettings?.data.country_code : 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: this.$siteSettings?.data?.business_type == 2 ? 1 : 2,
            },
            styleObject: {
                display: "none",
            },
            loading: false,
            demoLoading: false,
            passwordFieldType:'password',
            init:false,
            phnInit:false,
            loginMethod: 'password',
            minutes: 5,
            seconds: 59,
            loadingOtp: false,
            inputvalue :[
                {
                    value: 100,
                    isSelected: false,
                },
                {
                    value: 500,
                    isSelected: false,
                },
                {
                    value: 1000,
                    isSelected: false,
                },
                {
                    value: 2000,
                    isSelected: false,
                }                    
            ],
            loggedUserCheck: null,
        };
    },
    methods: {
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';
            }
        },

        demoUserLogin() {
            this.form.type = 1;
             this.form.userId="demouser";
            this.form.password = "demopass";
            this.loginServiceCall('DEMO_LOGIN_API', 'demo')
        },
        refreshData(formtype = 1) {
            this.form = {
                code: this.$siteSettings?.data && this.$siteSettings?.data.country_code ? this.$siteSettings?.data.country_code : 91,
                mobileNo: "",
                userId: "",
                password: "",
                type:formtype,
                "cncode": this.form.code,
            },
            this.loginMethod = 'password';
            this.isOtpApiCalled = false;
            this.minutes = 5;
            this.seconds = 59;
            this.passwordFieldType = 'password';
            this.init = false
            this.phnInit = false;
            this.toggleLogin();
        },
        signInMethodCall() {
            this.init = true;
            let id = this.getId();
            if(id && this.form.password) {
                this.loginMethod == 'otp' ? this.signInwithOtp() : this.loginServiceCall('LOGIN_API', 'nondemo');
            }
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };
            setTimeout(countdown, 1000);
        },
        async sendOtpServiceCall() {
            if(this.form.mobileNo.toString().length != 10) {
                this.$errorToast(this.$t('enter-proper-phn-num','Please enter proper mobile number')) 
                return;
            };
            this.loadingOtp = true;
            this.$apiCall('post', 'SEND_OTP', { mobile_number: this.form.mobileNo, cncode: this.form.code }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.$successToast(response.data.message);
                        this.isOtpApiCalled = true;
                        this.minutes = 5;
                        this.seconds = 0;
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.$errorToast(error[0])
            });
        },
        signInwithOtp() {
            let id = this.getId();
            this.loading = true;
            let data = {
                mobile: id,
                countrycode: this.form.code,
                otp: this.form.password
            };
            this.$apiCall('post', 'LOGIN_WITH_OTP', data).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$errorToast(response.data.debug[0]);
                        } else {
                        
                            this.$successToast(response.data.message);
                            let userData = response.data.data;
                            if (this.loggedUserCheck != userData.userid) {
                                this.$store.dispatch('setClickBetValue', this.inputvalue);
                            }          
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            this.$store.dispatch('setSiteVersion', null);
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.setUserIdsToStore(userData.userid)
                            localStorage.setItem("LoggedUser",userData.userid)
                            this.refreshData(1);
                            this.$router.push({name:'Home'});
                            setTimeout(() => {
                                location.reload();
                            }, 100);
                        }
                    }
                }
            }).catch(error => {

                this.loading = false;
                if (error) {
                    this.$errorToast(error[0]);
                }
            });
        },
        async loginServiceCall(url,type) {
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let id = this.getId();
            let data = {
                "cncode": this.form.code,
                "userid": id.toString(),
                "type": this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
            };
            this.$apiCall('post', url, data).then(response => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$errorToast(response.data.debug[0]);
                        } else {
                            this.$successToast(response.data.message);
                            let userData = response.data.data;
                            if (this.loggedUserCheck != userData.userid) {
                                this.$store.dispatch('setClickBetValue', this.inputvalue);
                            }
                            if (type === 'demo') {
                                userData.demoLogin = true
                            } else {
                                userData.demoLogin = false
                            }
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            // for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            localStorage.setItem('login_type', data.type)
                            this.$store.dispatch('setSiteVersion', null);
                            setHeaders();
                            localStorage.setItem("LoggedUser", userData.userid)
                            this.setUserDataToStore(userData);
                            this.setUserIdsToStore(userData.userid)
                            this.refreshData(data.type);
                            this.$router.go();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.$errorToast(error[0]);
                }
            });
        },


        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.$siteSettings?.data?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                asciiCodes: asciiCodes,
                isDemoLogin: userData.demoLogin,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData?.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        },
        setUserIdsToStore(userId) {
            let oldUserIds = this.$store.getters?.logInData.userIds
            if(this.form.type === 2) {
                let newUserIdsList = [...new Set([...oldUserIds, userId])]
                let data = {
                    logInWithMobile: false,
                    userIds: newUserIdsList
                }
                this.$store.dispatch('setLogInData', data);
            }
            else {
                let data = {
                    logInWithMobile: this.form.type == 1,
                    userIds: oldUserIds
                }
                this.$store.dispatch('setLogInData', data);
            }
        },
        toggleLogin() {
            const LoginBtn = document.getElementById('LoginBtn');
            if (LoginBtn) LoginBtn.classList.remove('active');
        }
    },
    mounted() {
        if(localStorage.getItem('login_type') && this.$siteSettings?.data?.business_type == 2) {
            this.form.type = localStorage.getItem('login_type');
        }
        this.loggedUserCheck = localStorage.getItem('LoggedUser')
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    },

};
</script>

<style scoped>

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #B5DDF0;
    }

    50% {
        background-color: #CFE9F6;
    }

    100% {
        transform: scale(1);
        background-color: #FFFFFF;
    }
}

.btn.btn-loading span {
    display: flex;
    justify-content: center;
}

.btn.btn-loading span b {
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: stretch;
    animation-play-state: running;
    animation-timing-function: ease-out;
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
    animation-delay: 0.1s;
    margin: 0 5px;
}

.btn.btn-loading span b:first-child {
    animation-delay: 0s;
    margin: 0;
}

.btn.btn-loading span b:last-child {
    animation-delay: 0.2s;
    margin: 0;
}

.btn span {
    display: none;
}

.placed-bet-btn.btn-loading {
    padding: 10px;
}

.btn-disabled {
    pointer-events: none;
    opacity: .65;
}
.login-option .btn-submit {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}
.login-option .btn-submit img {
    height: 20px;
    width: 20px;
}

</style>