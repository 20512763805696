import axios from "axios";
import { reactive } from "vue";

const langState = reactive({
    langData: {},
});
let init = true;
let currentLanguageData = null;
let awsUrl = null;



const setCurrentLangData = async (langList, langCode = null, langAws = awsUrl) => {
	try {
		if (!awsUrl) {
			awsUrl = langAws;
		}
		let selectedLangObj = JSON.parse(localStorage.getItem('selectedLanguageObj'));

		if ((!init && (!selectedLangObj && langCode)) || (!init && (selectedLangObj && langCode != selectedLangObj.lang_code)) || (!selectedLangObj && init)) {
			selectedLangObj = langList.find(lang => lang.lang_code === langCode);
		}

		if (!selectedLangObj || selectedLangObj.lang_code === 'en') {
			langState.langData = {};
			localStorage.removeItem('selectedLanguageObj');
			if ((!currentLanguageData && !init) || (currentLanguageData?.lang_code == selectedLangObj.lang_code && !init) ){
				if(selectedLangObj?.lang_code == 'en')
					currentLanguageData = selectedLangObj;
				return Promise.reject('Cannot update on same language. Try different language.')
			}
			else{
				if(selectedLangObj?.lang_code == 'en')
					currentLanguageData = selectedLangObj;
				return Promise.resolve('success');
			}
		}

		if (currentLanguageData && currentLanguageData.lang_code == langCode && Object.keys(langState.langData).length > 0) {
			return init ? false : Promise.reject('Cannot update on same language. Try different language.');
		}

		try {
			let response = await callForLoadTranslation(selectedLangObj.lang_url, awsUrl);
			langState.langData = response.data;
			currentLanguageData = selectedLangObj;
			init = false;

			localStorage.setItem('selectedLanguageObj', JSON.stringify(selectedLangObj));
			return init ? true :  Promise.resolve('success');
		} catch (error) {
			return Promise.reject('Failed to change language. Try different language.')
		}

	} catch (e) {
		let checkInit = init;
		init = false;
		return checkInit ? false : Promise.reject('Failed to change language. Try different language.');
	}

};

const translatedData = (key, defaultVal) => {
	if (langState.langData?.[key]) {
		return langState.langData?.[key];
	} else {
		return defaultVal;
	}
};

const callForLoadTranslation = async (translationUrlData, awsUrl) => {
	try {
		const response = await axios.get(`${awsUrl}/${translationUrlData}`, {
			headers: { Authorization: '' },  // Override with an empty value
		});
		return response;
	} catch (error) {
		console.log(error);
	}	
}


export {setCurrentLangData, translatedData};