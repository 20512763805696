import { createApp, reactive } from 'vue';
import App from './App.vue'
import store from './store'

import router from "./router"
import VueCookies from 'vue3-cookies';
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/fontawesome/css/all.css";
import "./assets/css/responsive.css";
// import "./assets/css/style-dark.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css'
import './registerServiceWorker'
import globalProperties from './globalServices';
// import "./assets/js/bootstrap.bundle.min.js";
// import "./assets/js/jquery.min.js";
// import jQuery from "jquery"


// window.$ = window.jQuery = require('jquery');
//import Interceptors from '@/shared/services/Interceptors'

// Import one of the available themes

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

//import "./assets/js/owl.carousel.min.js";
// Initialize the theme state
const themeState = reactive({
  currentTheme: localStorage.getItem('currentTheme') || 'light'
});

// window.addEventListener('popstate', () => {
//   if(document.querySelector('.modal.show')) {
//       window.location.reload();
//   }
// });

// Function to set the theme
function setTheme(theme) {
  const themeLinkId = 'theme-link';
  let themeLink = document.getElementById(themeLinkId);
  if (!themeLink) {
    themeLink = document.createElement('link');
    themeLink.id = themeLinkId;
    themeLink.rel = 'stylesheet';
    document.head.appendChild(themeLink);
  }

  if (theme === 'dark') {
    themeLink.href = '../../css/style-dark.css';
  } else {
    themeLink.href = './assets/css/style.css';
  }

  themeState.currentTheme = theme;
  localStorage.setItem('currentTheme', theme);
}

// Function to toggle the theme
function toggleTheme() {
  const newTheme = (themeState.currentTheme === 'light' || (localStorage.getItem('currentTheme') === 'dark' && themeState.currentTheme !== 'dark')) ? 'dark' : 'light';
  setTheme(newTheme);
}

// Create the Vue app instance
const app = createApp(App);
app.use(globalProperties);

// Add the theme state and toggle function to global properties
app.config.globalProperties.$themeState = themeState;
app.config.globalProperties.$toggleTheme = toggleTheme;

// Set the initial theme
setTheme(themeState.currentTheme);

// Use plugins
app.use(store);
app.use(router);
app.use(VueCookies);

// Define global properties
app.config.globalProperties.fantasy_server = 'fantasycricket';
app.config.globalProperties.click_server = 'fantasy';

// Mount the app
app.mount('#app');

// Axios setup
window.axios = require('axios');

router.afterEach(() => {
  setTimeout(() => {
      window.scrollTo({ top: 0 });
  }, 500);
});

// popup close issue
window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

// Router guard
router.beforeEach((to, from, next) => {
  if(document.querySelector('.modal.show')) {
      next(false);
  }
  if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'Profile'){
    window.router.push({ name: 'Profile'});
  }
  // if(document.querySelector('.modal.show')) {
  //   next(false);
  // } else {
  //   next();
  // }
    const isLoggedIn = store.getters.isAuthenticated;
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';

    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (to.path === '/') {
            if (isLoggedIn) {
                next('/home');
            } else {
                next();
            }
        } else if (to.path !== '/') {
            if (isLoggedIn) {
                next();
            } else {
                next('/');
            }
        }
    }
});
