<template>
    <div class="inplay-details-page-section">
        <div class="container-fluid">
            <div class="input-field-section trm-condations-sec rule_info_wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="rule-details-page" v-html="siteRuleData?.General"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rules',
    data(){
        return{
            siteRuleData:null
        }
    },
    mounted(){
        this.getRules();
    },
    methods:{
        getRules(bonus = false) { 
            this.$apiCall('get','GET_SITE_RULES').then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$errorToast( response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$errorToast( error[0]);
                }
            });
        },
    }

}
</script>

<style></style>