const state = {
    cricFightMatchDetails: null,
    cricFightPlayersList: null,
    tutorials: null,
    teamData: null,
    selectedPlayers: null,
    myContest: null,
    contestType: null,
    updatedTeamName: null,
};
const getters = {
    cricFightMatchDetails: state => state?.cricFightMatchDetails,
    cricFightPlayersList: state => state?.cricFightPlayersList,
    tutorials: state => state?.tutorials,
    teamData: state => state?.teamData,
    selectedPlayers: state => state?.selectedPlayers,
    myContest: state => state?.myContest,
    contestType: state => state?.contestType,
    updatedTeamName: state => state?.updatedTeamName,
};
const actions = {
    setCricFightMatchDetails({ commit }, cricFightMatchDetails){
        commit('setCricFightMatchDetails', cricFightMatchDetails)
    },
    setCricFightPlayersList({ commit }, cricFightPlayersList){
        commit('setCricFightPlayersList', cricFightPlayersList)
    },
    setTutorials({ commit }, tutorials){
        commit('setTutorials', tutorials)
    },
    setTeamData({ commit }, teamData){
        commit('setTeamData', teamData)
    },
    setSelctedPlayers({ commit }, selectedPlayers){
        commit('setSelctedPlayers', selectedPlayers)
    },
    setMyContest({ commit }, myContest){
        commit('setMyContest', myContest)
    },
    setContestType({ commit }, contestType){
        commit('setContestType', contestType)
    },
    setUpdatedTeamName({ commit }, updatedTeamName){
        commit('setUpdatedTeamName', updatedTeamName)
    }
};
const mutations = {
    setCricFightMatchDetails(state, cricFightMatchDetails){
        state.cricFightMatchDetails = cricFightMatchDetails 
    },
    setCricFightPlayersList(state, cricFightPlayersList){
        state.cricFightPlayersList = cricFightPlayersList
    },
    setTutorials(state, tutorials){
        state.tutorials = tutorials
    },
    setTeamData(state, teamData){
        state.teamData = teamData
    },
    setSelctedPlayers(state, selectedPlayers){
        state.selectedPlayers = selectedPlayers
    },
    setMyContest(state, myContest){
        state.myContest = myContest
    },
    setContestType(state, contestType){
        state.contestType = contestType
    },
    setUpdatedTeamName(state, updatedTeamName){
        state.updatedTeamName = updatedTeamName
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};