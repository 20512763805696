import store from "@/store/index.js";

const eventManage = store.getters.eventManage;
const isAuth = store.getters.isAuthenticated;

const checkForLockedMatches = (matchId, league_id, lockedMatchIds, lockedLeagueIds) => {
    return isAuth && (lockedMatchIds.length || lockedLeagueIds.length) ? !lockedMatchIds.includes(matchId.toString()) && !lockedLeagueIds.includes(league_id.toString()) : true
};

const checkEventManageforSingleType = (type) => {
    if(isAuth) {
        let sportEventManage = eventManage.find(item => item.type == type);
        if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
            return true
        }
        return !sportEventManage
    }
    return true;    
};

const checkForPremiumFancyEvent = () => {
    if (isAuth && eventManage) {
        let sportEventManage = eventManage.find(item => item.type == 'premium-fancy');
        return !sportEventManage
    }
    return true;
};

const checkEventManageforSports = (sportId) => {
    if (isAuth) {
        let sportEventManage = eventManage.find(item => item.type == 'sports');
        if (sportEventManage) {
            let sportIds = sportEventManage.type_id.split(',');
            return !sportIds.includes(sportId.toString());
        }
    }
    return true;
};

const CheckEventMangeForCasinoInSearch = (gamesData) => {
    try {
        let LockedProvider = eventManage?.find(item => item.type == 'casino-provider');
        let LockedProviderGames = eventManage?.find(item => item.type == 'provider-game');
        LockedProvider = LockedProvider && LockedProvider.type_id !== null ? LockedProvider.type_id.split(',') : [];
        LockedProviderGames = LockedProviderGames && LockedProviderGames.type_id !== null ? LockedProviderGames.type_id.split(',') : [];        
        return  gamesData?.filter((item) => !LockedProvider.includes(item?.provider_id.toString()) && !LockedProviderGames.includes(item?.id.toString()));
    } catch (e) {
        return gamesData;
    }
};

const checkInEventMangeForSection = (gamesData) => {
    try {
        let LockedProvider = eventManage?.find(item => item.type == 'casino-provider');
        let LockedProviderGames = eventManage?.find(item => item.type == 'provider-game');
        LockedProvider = LockedProvider && LockedProvider.type_id !== null ? LockedProvider.type_id.split(',') : [];
        LockedProviderGames = LockedProviderGames && LockedProviderGames.type_id !== null ? LockedProviderGames.type_id.split(',') : [];
    
        let lockCondition = (typeId, gameCode, LockedProvider, LockedProviderGames) => { 
            return (LockedProvider.length || LockedProviderGames.length) ? !(LockedProviderGames.includes(gameCode.toString())) && !(LockedProvider.includes(typeId.toString())) :true;
        }

        gamesData.sections.forEach((item,key) => {
            if(item?.menus?.length) {
                item.menus.forEach(mItem => {
                    mItem.games = mItem.games.filter(menuGame => lockCondition(menuGame.provider_id,menuGame.id, LockedProvider, LockedProviderGames)) 
                })
                item.menus = item.menus.filter(menuItems => menuItems.games.length > 0);
            } else if(item?.games?.length) {
                item.games = item.games.filter(gItem => lockCondition(gItem.provider_id,gItem.id, LockedProvider, LockedProviderGames)) 
            }
        })
    
        gamesData.providers = gamesData.providers.filter(provider => !(LockedProvider.includes(provider.id.toString())));
    
    } catch (e) {
        return gamesData;
    }
};

export {
    checkForLockedMatches,
    checkEventManageforSports,
    checkInEventMangeForSection,
    checkForPremiumFancyEvent,
    checkEventManageforSingleType,
    CheckEventMangeForCasinoInSearch
}
