<template>
	<router-view v-if="underMaintenance"></router-view>
	<template v-else>
		<MobileSideMenu></MobileSideMenu>
		<div class="inplay-details-sec" v-if="$auth.status && this.$route.name != 'Home'">
			<div class="row g-1">
				<div class="col-0 col-sm-0 col-md-0 col-lg-2">
					<SideMenu ></SideMenu>
				</div>
				<div class="col-12 col-sm-12 col-md-12 col-lg-10">
					<div class="main-page-details">
	
						<MainHeader v-if="checkForHeaderShow()"></MainHeader>
	
						<!-- //inplay-page-start here/ -->
						<transition name="fade">
							<div class="preloader d-flex align-items-center justify-content-center" v-if="getGlobaLoader()">
								<div class="cssload-container">
									<div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
								</div>
							</div>
						</transition>
						<router-view  v-if="this.$siteSettings.data || underMaintenance"></router-view>
	
						<!-- //end here// -->
					</div>
				</div>
			</div>
			<MobileFooter v-if="checkForFooterShow()"></MobileFooter>	
			<Account  @updateAuthToken="refreshAuthToken" />
		</div>

	</template>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
		</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>
	<router-view v-if="this.$route.name == 'Home'" ></router-view>
	<div  v-if="successMsg" :class="{ show: successMsg }" class="successfully-wrapper" style="z-index: 9999999999999999;"
		ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div v-if="errorMsg" :class="{ show: errorMsg }" style="z-index: 9999999999999999;" class="successfully-wrapper"
		id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>
</template>

<script>
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import socketService from "./socket/sport-socket-service";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
import NotificationAlert from './shared/components/modal/NotificationAlert.vue';
import { checkForLockedMatches, checkInEventMangeForSection, checkEventManageforSports } from '@/shared/services/eventLocks.js';

export default {
	name: 'App',
	components: {
		MainHeader: defineAsyncComponent(() => 
		import('./shared/components/header/MainHeader.vue')
		),
		MobileSideMenu: defineAsyncComponent(() => 
		import('./shared/components/header/MobileSideMenu.vue')
		),
		SideMenu: defineAsyncComponent(() => 
		import('./shared/components/header/SideMenu.vue')
		),
		MobileFooter: defineAsyncComponent(() =>
		 import('./shared/components/footer/MobileFooter.vue')
		),
		SuccessModal: defineAsyncComponent(() =>
		 import('./shared/components/modal/SuccessModal.vue')
		),
		ErrorModal: defineAsyncComponent(() =>
		 import('./shared/components/modal/ErrorModal.vue')
		),
		Account: defineAsyncComponent(() => 
		import('./modules/account/components/Account.vue')
		),
		NotificationAlert
	},

	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			loading: false,
			isLoading: true,
			refreshInit: false,
			langData: null,
			findActiveLang:"",
			allLanguages:null,
			versionData:null,
			UpdatedVersion:null,
		}
	},
	computed: {
		...mapGetters(['successMsg', 'errorMsg', 'siteVersion','underMaintenance','eventDetailHideSections','globalLoader']),
		routePath() {
			return this.$route.name
		},
	},
	mounted() {
		document.body.classList.add('inplay-sec');
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		this.socketAllEvents()
		this.getSiteVersion();

		if (this.$auth.status) {
			if(!this.$auth.user?.notificationUnsubscribed && !this.$auth.user?.notificationSubscribed){
				this.requestNotificationPermission()
			}
		}
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		if (localStorage.getItem('default_class')) {
			document.documentElement.className = localStorage.getItem('default_class')
		}
		this.$errorToast(null);
		this.$successToast(null);
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},

	methods: {
		checkInEventMangeForSection,
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data, 'notification').then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.$errorToast(error[0])
				});
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},

		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
						// this.$store.dispatch('setGlobalLoader', false);
					} else {
						let resData = response?.data?.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;						
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.getSiteSettingsServiceCall();
						} else {
							this.setSiteSttings(this.$siteSettings.data);
						}
						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.$errorToast(error[0]);
				}
			});
		},

		getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {

						let resData = response?.data.data;
						this.$store.dispatch('setSettings', resData.site_settings);
						let casino;
						if(response?.data.data.casino_url) {
							const getCasinoGame = async () => {
							let gameResp = await axios.get(response?.data.data.casino_url)
							return gameResp.data
							};
							casino = await getCasinoGame();
						} else {
							casino = response?.data.data?.casino
						}	
						this.$store.dispatch('setGamesData', casino);
						this.checkInEventMangeForSection(casino);
						this.setSiteSttings(resData.site_settings);

					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},

		setSiteSttings(siteData) {
			if (siteData.language_translate_list) {
				this.$setCurrentLangData(siteData.language_translate_list, siteData.language_code, siteData.aws_url)
				this.$store.dispatch('setLanguagesData', siteData.language_translate_list);
			}

			this.$store.dispatch('setSettings', siteData);
			this.setManifest(siteData);

			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);

			// let is_offer = localStorage.getItem("is_offer");
			// if (this.$auth.status && this.$siteSettings?.data?.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2) {
            //     this.$helper.openModal('#offer_pop_up')
			// }

			const title = document.getElementById("title");
			title.text = siteData.domain_name;
			if (this.$auth.status) {
				this.getWalletBalance();
				this.getFavourites();
			}
			if(siteData.meta_tags) {
					document.head.innerHTML += siteData.meta_tags;
				};
			if(siteData.g_tags) {
				this.setGoogleAnalyticsTag(siteData.g_tags);
			};
			if(siteData.site_map) {
				const sitemapLink = document.createElement("link");
				sitemapLink.rel = "sitemap";
				sitemapLink.type = "application/xml";
				sitemapLink.title = "Sitemap";
				sitemapLink.href = siteData.aws_url + siteData.storage_path.site_map + siteData.site_map;
				document.head.appendChild(sitemapLink);
			};
			try {
				if (siteData.front_css != null && siteData.front_css != '') {// Create a style element
					const styleElement = document.createElement('style');
					styleElement.type = 'text/css';
					styleElement.appendChild(document.createTextNode(siteData.front_css));
					document.head.insertAdjacentElement('beforeend', styleElement);
				}
			}
			catch (error) {
				console.log("ERROR 1: ", error);
			}
		},
		setGoogleAnalyticsTag(gTag) {
			let script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;

			let script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${gTag}');
			`;

			document.head.appendChild(script1);
			document.head.appendChild(script2);
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);

			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response?.data?.bl;
					user.expl = response?.data?.ex;
					user.bets_count = response?.data?.bc;
					user.earnBonus = response.data.eb;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken();
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					console.log(error);
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.refreshInit = true;
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if(refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					console.log("Error Login : ", error[0]);
					this.$errorToast(error[0])
				}
				this.refreshInit = false;
			});
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.$successToast('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						//	this.$successToast('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		checkForHeaderShow() {
			const headerHideRoutes = ['NotFound']
			if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'SportsEventDetail' || headerHideRoutes.includes(this.routePath)) {
				return false;
			} 
			else {
				return true;
			}
		},
		checkForFooterShow() {
			const headerHideRoutes = ['NotFound']
			if (headerHideRoutes.includes(this.routePath)) {
				return false;
			} 
			else {
				return true;
			}
		},
	},

	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to {
	opacity: 0
}
</style>
