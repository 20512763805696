<template>
    <div class="modal fade " id="staticBackdrop-two" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img loading="lazy" src="@/assets/images/tigerexch-logo.webp" alt="tigerexch-logo">
                        </figure>
                        <button ref="close_btn" @click="refreshData()" type="button" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="close-btn">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form">
                            <h2 class="register-title">{{ $t('forgot-password-cap','FORGOT PASSWORD')}}</h2>
                            <!-- <p class="create">
                                Enter Your Phone And We'll Send <br>
                                You A Otp To Get Back Into Your Account.
                            </p> -->
                            <!-- <hr class="form-divider-line"> -->
                             <div class="form-group">
                                <div class="get-mob">
                                    <!-- <label for="" class="form-cmn-title">{{ $t('mobile-number','Mobile Number')}}*</label> -->
                                    <div class="phone-inpt-sec">
                                        <div class="slt-country-code">
                                            <div class="col-auto">
                                                <select v-model="form.code" class="form-select" id="autoSizingSelect" :disabled="otpSent">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="input-number-box">
                                            <input type="tel" v-model="form.mobileNo" maxlength="10" minlength="10" 
                                                :placeholder="$t('mobile-number','Mobile Number') + '*'"  class="form-control add"
                                                :disabled="timerRunning || otpSent" @input="form.mobileNo = $helper.sanitizeNumber(form.mobileNo,9999999999);">
                                            <div class="get_otp_auth_btn">
                                                <button :disabled="timerRunning || otpLoading" @click="otpBtnCall()"
                                                    class="btn cmn-bdr-btn otp-btn">
                                                    <span v-if="timerRunning">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                                    <span v-else>{{ resendOtp ? $t('resend-otp' , 'Resend OTP') : $t('get-otp' , 'Get OTP') }} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="phnInit && checkValidation?.mobile?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.mobile" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="otpBtnInit && form.mobileNo?.length == 10 && !timerRunning && getOTPmsg && !isOtpApiCalled">
                                    <p  class="wrong-color">{{$t('please-click-on-send-otp','Please Click On Get OTP')}}</p>
                                </div>
                            </div>

                            <!-- <div class="mak-gin mo-number-right mt-2 mb-2">
                                <p v-if="timerRunning">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</p>
                                <button :disabled="timerRunning || isOtpApiCalled" @click="sendOtpCall();phnInit=true;otpBtnInit=true;" class="btn cmn-bdr-btn otp-btn">
                                    {{ resendOtp ? $t('resend-otp' , 'Resend OTP') : $t('get-otp' , 'Get OTP') }}   
                                </button>
                            </div> -->

                            <div class="form-group" v-if="otpSent">
                                <!-- <label>OTP</label> -->
                                <div class="get-mob">
                                    <input v-model="form.otp" type="tel" @input="form.otp = $helper.sanitizeNumber(form.otp, 999999);" minlength="6" maxlength="6" class="form-control" id="inputPassword2" :placeholder="$t('enter-otp','OTP') + '*'">
                                    <div class="inp-img"><img loading="lazy" src="@/assets/images/key_icon.svg" alt="key"></div>
                                </div>
                                <div class="password-wrong-con" v-if="init && checkValidation?.otp?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.otp" :key="index"   class="wrong-color">{{ $t(err.lang, err.error) }}.</p>
                                </div>
                            </div>

                            

                            <div class="form-group pass-m-top">
                                <!-- <label for="" class="form-cmn-title">{{$t('password','Password')}}*</label> -->
                                <div class="get-mob">
                                    <input :type="passwordFieldType" required="1" v-model="form.password" @input="form.password = $helper.sanitizeDetails('password', form.password, 20);" class="form-control" minlength="8" maxlength="20" id="inputPass4" :placeholder="$t('enter-pass','Password') + '*'">
                                    <div class="inp-img password-icon"><img loading="lazy" src="@/assets/images/password_icon.svg" alt="pass"></div>
                                    <div class="eye-icn-sec" @click="toggleShow('password')">
                                        <img loading="lazy" v-if="passwordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="eye-open">
                                        <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="eye-close">
                                    </div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.password?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.password" :key="index"  class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>

                            
                    
                            <div class="form-group mb-0">
                                <!-- <label for="" class="form-cmn-title">{{$t('confirm-password','Confirm Password')}}*</label> -->
                                <div class="get-mob">
                                    <input :type="confPasswordFieldType" v-model="form.confirmPassword" class="form-control" id="inputPass5" minlength="8" maxlength="20"
                                        :placeholder="$t('confirm-password','Confirm Password') + '*'" @input="form.confirmPassword = $helper.sanitizeDetails('password', form.confirmPassword, 20);">
                                    <div class="inp-img password-icon"><img loading="lazy" src="@/assets/images/password_icon.svg" alt="pass"></div>
                                    <div class="eye-icn-sec" @click="toggleShow('conf-password')">
                                        <img loading="lazy" v-if="confPasswordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="eye-open">
                                        <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="eye-close">
                                    </div>
                                </div>
                                <div class="justify-content-start password-wrong-con" v-if="init && checkValidation?.confirmPassword?.length > 0">
                                    <p v-for="(err, index) in checkValidation?.confirmPassword" :key="index" class="wrong-color">{{ $t(err.lang, err.error) }}</p>
                                </div>
                            </div>

                            
                            <div class="get-mob">
                                <a @click="forgetPasswordCall()" class="btn btn-submit btn-login">
                                     {{$t('update-password','Update Password')}}
                                </a>
                            </div>


                            <p class="forpass-in">{{$t('remember-your-password','Remember Your Password')}}? <a data-bs-toggle="modal" href="#loginModal" @click="refreshData(); $emit('open-login')">{{$t('login','Login')}}</a></p>
                            <p class="forpass-in"><a data-bs-toggle="modal" href="#Register" @click="refreshData(); $emit('open-register')">{{$t('create-new-account','Create New Account')}} </a></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import commonAuth from '../services/commonAuth';
import validate from '../services/authValidation';
export default {
    name: "ForgetPassword",
    extends: commonAuth,
    data() {
        return {
            form: {
                code: this.$siteSettings?.data && this.$siteSettings?.data.country_code ? this.$siteSettings?.data.country_code : 91,
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
            },
            phnInit:false,
            loadingUpdate:false,
            otpBtnInit:false
        };
    },
    computed: {
        checkValidation() {
            const validationErrors = validate.areFieldsValid(
                'forgot-password',
                this.form.mobileNo, 
                this.form.otp, 
                this.form.password,
                this.form.confirmPassword,
            );
            if(this.init || this.phnInit){
                return validationErrors;
            }
        }
    },
    methods: {
        forgetPasswordCall() {
            this.phnInit = true;
            this.otpBtnInit = true;

            if (!this.otpSent){ 
                return
            } else {
                this.init = true;
                if(Object.keys(this.checkValidation).length === 0) {
                    this.forgotServiceCall();
                }
            }
        },
        otpBtnCall(){
            this.getOTPmsg = true;
            this.phnInit=true;
            this.otpBtnInit=true;
            if(!this.checkValidation?.mobile?.length && !this.timerRunning) {
                this.sendOtpCall();
            }
        },
        async forgotServiceCall() {
            this.loadingUpdate= true;
            let data = {
                "phone": this.form.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),

            };
            this.$apiCall('post','FORGOT_PASSWORD', data).then(response => {
                if (response) {
                    this.loadingUpdate = false;
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.$errorToast(response.data.debug[0]);
                        }
                        else {
                            this.$successToast(response.data.message);
                            this.refreshData();
                            this.$helper.closeModal('staticBackdrop-two');
                            this.$helper.showLogin()
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.$errorToast(error[0]);
                }
            });
        },
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';

            } else {
                this.confPasswordFieldType = (this.confPasswordFieldType === 'text') ? 'password' : 'text';
            }
        },
    },
};
</script>
<style scoped>
.pass-vali-div {
    display: flex;
    gap: 5px;
    margin-top: 5px;
}
.pass-vali-div p{
    font-size: 12px;
    font-weight: bold;
}
.succ-wrong-img {
    width: 15px;
    height: 15px;
}
.success-color{
    color: #27ae60;
}
.bal-color {
    color: #4caf50 !important;
}
.exp-color {
    color: #ff3636 !important;
}
</style>