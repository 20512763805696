<template>
    <div class="nodata_found_wrapper">
        <div class="notfound_img">
            <img src="@/assets/images/not-found.png">
        </div>
        <h1>404</h1>
        <h3>{{ $t('not-found', 'Not Found') }}</h3>
        <router-link :to="{ name: 'Home' }" class="btn_submit_cl">{{ $t('go-to-home-page', 'Go To Home Page') }}</router-link>
    </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.nodata_found_wrapper {
    text-align: center;
    padding: 20px 10px;
    min-height: calc(100dvh - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.notfound_img img {
    max-height: 200px;
}
.notfound_img {
    margin-bottom: 20px;
}
.nodata_found_wrapper h1 {
    font-size: 72px;
    font-weight: 900;
    color: #ef8c16;
    line-height: 70px;
}
.nodata_found_wrapper h3 {
    font-size: 30px;
    margin: 10px 0 20px;
    color: #606060;
    font-weight: 500;
}
a.btn_submit_cl {
    background-image: linear-gradient(to bottom right, #FFC558, #FF7300);
    border-radius: 30px;
    color: #000;
    text-transform: uppercase;
    padding: 12px 40px;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
}
</style>
